import { graphql } from 'gatsby';
import {
  arrayOf, bool, shape, string,
} from 'prop-types';
import React from 'react';
import BEMHelper from 'react-bem-helper';
import { useTranslation } from 'react-i18next';

import { Content } from '../../common/content';
import { useAuth } from '../../../hooks/useAuth';
import { useDownload } from '../../../hooks/useDownload';
import './download.scss';

const bem = new BEMHelper({
  name: 'content-download',
});

export const Download = ({
  id,
  download: [download],
}) => {
  const {
    title,
    file: [file],
    mailinglist: [mailinglist],
    isPublic,
  } = download;

  const { t } = useTranslation();
  const { isAuthenticated } = useAuth();
  const { startDownload } = useDownload({
    mailinglist,
    url: file.url,
  });

  if (!isPublic && !isAuthenticated) {
    return (
      <Content id={id} {...bem()} useOwnSpacing>
        <div {...bem('container')}>
          <button
            {...bem('button', 'locked')}
            type="button"
            title={t('Please login to access this document')}
          >
            <span {...bem('button__separator')}>: </span>
            <span {...bem('button__label')}>{t('This file is not public. Please login to access this document.')}</span>
          </button>
        </div>
      </Content>
    );
  }

  return (
    <Content id={id} {...bem()} useOwnSpacing>
      <div {...bem('container')}>
        <button
          {...bem('button')}
          type="button"
          title={t('Click here to download')}
          onClick={startDownload}
        >
          <span {...bem('button__action')}>{t('Download')}</span>
          <span {...bem('button__separator')}>: </span>
          <span {...bem('button__label')}>{title}</span>
        </button>
      </div>
    </Content>
  );
};

Download.propTypes = {
  id: string.isRequired,
  download: arrayOf(shape({
    title: string.isRequired,
    file: arrayOf(shape({
      url: string.isRequired,
    })).isRequired,
    isPublic: bool.isRequired,
    mailinglist: arrayOf(shape({
      headline: string.isRequired,
      description: string.isRequired,
      endpoint: string.isRequired,
    })).isRequired,
  })).isRequired,
};

export const fragment = graphql`
  fragment DownloadContentFragment on CraftGQL_pageContents_download_BlockType {
    id
    download {
      ...on CraftGQL_downloads_downloads_Entry {
        title
        file: downloadFile {
          url
        }
        isPublic: downloadIsPublic
        mailinglist: downloadMailinglist {
          ...on CraftGQL_mailinglists_mailinglists_Entry {
            headline: mailinglistHeadline
            description: mailinglistDescription
            endpoint: mailinglistEndpoint
          }
        }
      }
    }
  }
`;
