import loadable from '@loadable/component';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from './useAuth';
import { useModal } from './useModal';

const SignUpForm = loadable(() => import('../components/common/newsletter'));

const execute = (url) => {
  const segments = url.split('/');
  const filename = segments[segments.length - 1];
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  link.target = '_blank';
  link.rel = 'noopener';
  document.body.appendChild(link);
  link.textContent = filename;
  link.click();
  link.remove();
};

/*
 * A download can take two different routes. If an endpoint for a mailing list is
 * supplied, the user needs to add his email address. This will be done by
 * displaying a modal including the newsletter form. Until the user has typed in
 * his email and sign up into the mailing list, the download get triggered.
 *
 * If no endpoint for a mailing list is supplied, the download is executed
 * immediately.
 */
export const useDownload = (({ url, mailinglist }) => {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth();

  const { showModal, hideModal } = useModal(SignUpForm, {
    title: t('Get access for download'),
    componentProps: {
      submit: {
        label: t('Submit and download'),
        title: t('Click here to submit and start to download'),
      },
      mailinglist,
      onSuccess: () => {
        execute(url);
        hideModal();
      },
    },
  });

  const startDownload = useCallback(() => {
    if (mailinglist && !isAuthenticated) {
      showModal();
      return;
    }

    execute(url);
  }, [url, mailinglist]);

  return { startDownload };
});
